<template>
  <div class="container">
    <div class="title">
      <span class="iconfont icon-gou"></span>
      <span>{{ $t('payment_info.pay_success') }}</span>
    </div>
    <div class="btnBox">
      <div class="btn">
        <q-btn
          size="lg"
          color="primary"
          class="full-width"
          :label="$t('payment_info.order_detail')"
          @click="$router.push('/my/order')"
        />
      </div>
      <div class="btn">
        <q-btn
          size="lg"
          color="primary"
          outline
          class="full-width"
          :label="$t('payment_info.back_cart')"
          @click="$router.push('/cart')"
        />
      </div>
    </div>
    <div class="orderdetail">
      <div class="od_con">
        <div class="title2">{{ $t('payment_info.order_info') }}</div>
        <div>
          <p>{{ $t('payment_info.order_id') }}{{ info.order_no }}</p>
          <p>{{ $t('payment_info.pay_mode') }}{{ info.payplatmethod }}</p>
          <p>{{ $t('payment_info.create_time') }}{{ info.newdate }}</p>
          <p>{{ $t('payment_info.pay_time') }}{{ info.paymenttime }}</p>
          <p>
            {{ $t('payment_info.shop_address') }}{{ info.province
            }}{{ info.city }}{{ info.area }}{{ info.address }}
          </p>
          <p>{{ $t('payment_info.name') }}{{ info.name }}</p>
          <p>{{ $t('payment_info.phone') }}{{ info.tel }}</p>
          <p>{{ $t('payment_info.email') }}{{ info.email }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { payStatus } from '@/api/goods'
export default {
  data () {
    return {
      info: ''
    }
  },
  methods: {
    async getPayInfo () {
      const res = await payStatus({
        type: 2,
        mark: this.$route.query.oguid
      })
      this.info = res
    }
  },
  mounted () {
    this.getPayInfo()
  }
}
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 985px;
  margin: 0 auto;
  margin-top: 100px;
  padding: 0 10px;
  .title {
    font-size: 20px;
    span {
      vertical-align: middle;
    }
    .iconfont {
      width: 48px;
      height: 48px;
      background-color: #000;
      color: #fff;
      border-radius: 50%;
      margin-right: 10px;
      font-size: 50px;
    }
  }
  .btnBox {
    width: 100%;
    margin-top: 60px;
    display: flex;
    justify-content: center;
    .btn {
      width: 300px;
      margin: 0 15px;
    }
  }
  .orderdetail {
    border-top: 1px solid #8a8a8a;
    padding-top: 45px;
    width: 100%;
    margin-top: 67px;
    .od_con {
      width: 606px;
      margin: 0 auto;
      text-align: left;
      .title2 {
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        &::before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          background-color: #000;
          margin-right: 10px;
        }
      }
      & > div:nth-of-type(2) {
        margin-left: 26px;
        margin-bottom: 30px;
      }
      p {
        line-height: 20px;
      }
    }
  }
}
</style>
